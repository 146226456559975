import { useEffect, useState } from 'react';
import slugify from 'slugify';

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Image,
  Input,
  InputNumber,
  Select,
  Space,
  Upload,
} from 'antd';
import { DRAFT_STATUS, PUBLISH_STATUS } from 'utils/constants';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  onFinish,
  submiting,
  initialValues = {},
  topics,
  courses,
  name,
  onDelete,
  visibility = 'hidden',
}) => {
  const { photo, status } = initialValues;

  const onBlur = () => {
    const inputValue = form.getFieldsValue();
    !inputValue.slug &&
      form.setFieldsValue({ slug: slugify(inputValue.title).toLowerCase() });
  };

  const [isRemoved, setIsRemoved] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const removePhoto = () => {
    setIsRemoved(true);
  };

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  var draftButtonLabel = 'Save Draft';
  var publishButtonLabel = 'Publish';

  if (status == PUBLISH_STATUS) {
    publishButtonLabel = 'Save';
    draftButtonLabel = 'Switch to Draft';
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'item_form'}
      onFinish={values =>
        onFinish({ ...values, status: isDraft ? DRAFT_STATUS : PUBLISH_STATUS })
      }
      initialValues={{ ...initialValues, access: 1001 }}
      scrollToFirstError
      onBlur={onBlur}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Nền tảng chứng khoán căn bản" />
      </Form.Item>

      <Form.Item
        name="slug"
        label="URL Slug"
        tooltip="URL Slug"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input
          value={form.fieldValue}
          placeholder="nen-tang-chung-khoan-can-ban"
        />
      </Form.Item>

      <Form.Item
        name="highlight"
        label="Highlight Text"
        rules={[
          {
            required: false,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="Phổ biến cho người mới" />
      </Form.Item>

      <Form.Item name="description" label="Description">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item
        name="photo"
        label="Upload a new photo"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      {isRemoved || !photo ? null : (
        <Form.Item
          label="Existing photo"
          style={{
            visibility,
          }}
        >
          <Button
            type="primary"
            shape="circle"
            size="small"
            style={{
              backgroundColor: 'red',
              position: 'absolute',
              zIndex: 99,
              left: 5,
              top: 5,
            }}
            onClick={() => removePhoto()}
          >
            X
          </Button>
          <Image width={200} src={photo}></Image>
        </Form.Item>
      )}

      <Form.Item
        name="ranking"
        label="Course Number"
        tooltip="Smallest number will be on top: 1,2,3.."
      >
        <InputNumber />
      </Form.Item>

      <Form.Item
        name="count"
        label="Number of chapters"
        tooltip="Input how many chapters have in course"
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="level" label="Level">
        <Select>
          <Option value="easy">Easy</Option>
          <Option value="medium">Medium</Option>
          <Option value="hard">Hard</Option>
        </Select>
      </Form.Item>

      <Form.Item name="topic" label="Topic">
        <Select>
          {topics &&
            topics.map(topic => (
              <Option key={topic.id} value={topic.id}>
                {topic.title}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="value"
        label="Reward (vnd)"
        tooltip="Reward (vnd)"
        defaultValue={0}
      >
        <InputNumber />
      </Form.Item>

      <Form.Item name="duration" label="Duration (minutes)">
        <InputNumber />
      </Form.Item>

      <Form.Item name="access" label="Access Level">
        <Select>
          <Option key={1001} value={1001}>
            Public
          </Option>
          <Option key={1002} value={1002}>
            Private - Direct access but not listed
          </Option>
          <Option key={1003} value={1003}>
            Listed Only - Listing only but not direct access
          </Option>
        </Select>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button htmlType="submit" type="primary" loading={submiting}>
            {publishButtonLabel}
          </Button>
          <Button
            onClick={() => setIsDraft(true)}
            danger
            htmlType="submit"
            loading={submiting}
          >
            {draftButtonLabel}
          </Button>
          {onDelete ? (
            <Button onClick={onDelete} danger>
              Move to Trash
            </Button>
          ) : null}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;

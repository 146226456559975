import { AppstoreOutlined, ContainerOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

function getItem(label, key, url, icon, children, type) {
  return {
    key: url ? url : key,
    icon,
    children,
    label: url ? <NavLink to={url}>{label}</NavLink> : label,
    type,
  };
}

export const items = [
  getItem('Stag Admin', 'home', '/home'),
  getItem('Education Hub', 'hub', '', <ContainerOutlined />, [
    getItem(
      'Education',
      'course',
      null,
      null,
      [
        getItem('Courses', 'course', '/hub/courses'),
        getItem('Topics', 'topic', '/hub/topics'),
      ],
      'group'
    ),
    getItem(
      'Financial Tools',
      'Health Check',
      null,
      null,
      [getItem('Health Check', 'fhc', '/hub/fhc-reports')],
      'group'
    ),
  ]),
  getItem('Market Intelligence', 'market', '', <AppstoreOutlined />, [
    getItem('ETFs', 'etfs', '/market/etfs'),
    getItem('Mutual Funds', 'funds', '/market/funds'),
    getItem('Stocks', 'stocks', '/market/stocks'),
    getItem('Stock Events', 'stock-events', '/market/stock-events'),
    getItem('Cryptos', 'cryptos', '/market/cryptos'),
    getItem('Industries', 'industry', '/market/industries'),
    getItem('Investing Ideas', 'collection', '/market/collections'),
    getItem(
      'Collection Group',
      'collection-groups',
      '/market/collection-groups'
    ),
    getItem('News', 'news', '/market/news'),
    getItem('Industry Keywords', 'news-industries', '/market/news-industries'),
    getItem('Banks', 'bank', '/market/banks'),
  ]),
  getItem('Education', 'edu', '', <ContainerOutlined />, [
    getItem(
      'Education',
      'course',
      null,
      null,
      [
        getItem('Courses', 'course', '/edu/courses'),
        getItem('Topics', 'topic', '/edu/topics'),
      ],
      'group'
    ),
    getItem(
      'Challenge',
      'challenge',
      null,
      null,
      [
        getItem('Challenges', 'challenge', '/edu/challenges'),
        getItem(
          'Challenge Groups',
          'challenge-groups',
          '/edu/challenge-groups'
        ),
      ],
      'group'
    ),
    getItem(
      'Financial Tools',
      'Health Check',
      null,
      null,
      [getItem('Health Check', 'fhc', '/edu/fhc-reports')],
      'group'
    ),
    getItem(
      'Game',
      'game',
      null,
      null,
      [
        getItem('Game Rooms', 'game-rooms', '/edu/game-rooms'),
        getItem('Stock Dividends', 'stock-dividends', '/edu/stock-dividends'),
        getItem('Portfolios', 'portfolios', '/edu/portfolios'),
        getItem(
          'Portfolio Stocks',
          'portfolio-stocks',
          '/edu/portfolio-stocks'
        ),
      ],
      'group'
    ),
    getItem(
      'Stag Learn',
      'stag-learn',
      null,
      null,
      [
        getItem('Devices', 'user-devices', '/edu/user-devices'),
        getItem('Notifications', 'notification', '/edu/notifications'),
        getItem('Communities', 'communities', '/edu/communities'),
        getItem('Ads', 'advertisement', '/edu/advertisement'),
        getItem('Banners', 'articles', '/edu/banners'),
        getItem('Polls', 'poll', '/edu/polls'),
      ],
      'group'
    ),
  ]),
  getItem('Investment', 'investment', '', <ContainerOutlined />, [
    getItem('Fund Orders', 'order', '/investment/orders?ordering=-created_at'),
    getItem(
      'Stock Orders',
      'stock-order',
      '/investment/stock-orders?ordering=-created_at'
    ),
    getItem('Portfolios', 'portfolio', '/investment/portfolios'),
    getItem(
      'Account',
      'account',
      null,
      null,
      [
        getItem('User Accounts', 'user', '/investment/users'),
        getItem('Fund Accounts', 'order', '/investment/fund-accounts'),
        getItem('Stock Accounts', 'order', '/investment/stock-accounts'),
      ],
      'group'
    ),
    getItem(
      'Retail',
      'retail',
      null,
      null,
      [
        getItem('Programs', 'user-program', '/investment/user-programs'),
        getItem(
          'Orders',
          'user-program-orders',
          '/investment/user-program-orders'
        ),
      ],
      'group'
    ),
    getItem(
      'FutureFlex',
      'flex',
      null,
      null,
      [
        getItem(
          'Programs',
          'employee-program',
          '/investment/employee-programs'
        ),
        getItem('Orders', 'program-orders', '/investment/program-orders'),
        getItem('Companies', 'company', '/investment/companies'),
        getItem('Employees', 'employees', '/investment/employees'),
        getItem('HR Admin', 'staffs', '/investment/staffs'),
      ],
      'group'
    ),
  ]),
  getItem('Investcore', 'investcore', '', <ContainerOutlined />, [
    getItem(
      'Data Catalog',
      'data',
      null,
      null,
      [
        getItem('Banks', 'bank', '/investcore/banks'),
        getItem('Fund Products', 'product-fund', '/investcore/products/funds'),
        getItem('ETF Products', 'product-etf', '/investcore/products/etfs'),
      ],
      'group'
    ),
    getItem(
      'Monitoring',
      'monitoring',
      null,
      null,
      [
        getItem(
          'Notification Logs',
          'notification-logs',
          '/investcore/notification-logs'
        ),
      ],
      'group'
    ),
  ]),
];

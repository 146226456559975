const stagImg = process.env.REACT_APP_IMG_HOST || 'https://img.stag.vn';
console.log(process.env);

export function dataURItoBlob(dataurl, filename) {
  if (!dataurl) return null;

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function convertImageFormData(file, fieldName = 'photo') {
  const formData = new FormData();
  formData.append(fieldName, file);

  return formData;
}

export const cloudflareCdn = (url, width, height) => {
  if (!url) return null;

  if (!width & !height) {
    width = 474;
    height = 256;
  }

  let arr = [];
  if (height) arr.push(`height=${height}`);
  if (width) arr.push(`width=${width}`);
  arr.push('quality=75');
  arr.push('format=auto');

  const params = arr.join(',');

  if (url.startsWith('/')) {
    return `${stagImg}/cdn-cgi/image/${params}${url}`;
  } else if (!url.startsWith('http')) {
    return `${stagImg}/cdn-cgi/image/${params}/${url}`;
  }

  return fixedUrl;
};

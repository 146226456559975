import {
  ChapterCreatePage,
  ChapterEditPage,
  ChapterListPage,
} from 'apps/hub/components/Chapters';
import {
  CourseCreatePage,
  CourseEditPage,
  CourseListPage,
} from 'apps/hub/components/Courses';
import { FHCDetailPage, FHCListPage } from 'apps/hub/components/FHC';
import {
  LessonCreatePage,
  LessonEditPage,
  LessonListPage,
} from 'apps/hub/components/Lessons';
import {
  TopicCreatePage,
  TopicEditPage,
  TopicListPage,
} from 'apps/hub/components/Topics';

const hubRoutes = [
  { path: '/hub/fhc-reports', element: <FHCListPage /> },
  { path: '/hub/fhc-reports/:id', element: <FHCDetailPage /> },
  { path: '/hub/courses', element: <CourseListPage /> },
  { path: '/hub/courses/create', element: <CourseCreatePage /> },
  { path: '/hub/courses/:id', element: <CourseEditPage /> },
  { path: '/hub/courses/:id/edit', element: <CourseEditPage /> },
  { path: '/hub/courses/:course_id/chapters', element: <ChapterListPage /> },
  {
    path: '/hub/courses/:course_id/chapters/create',
    element: <ChapterCreatePage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:id',
    element: <ChapterEditPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:id/edit',
    element: <ChapterEditPage />,
  },
  { path: '/hub/topics', element: <TopicListPage /> },
  { path: '/hub/topics/create', element: <TopicCreatePage /> },
  { path: '/hub/topics/:id/edit', element: <TopicEditPage /> },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons',
    element: <LessonListPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/create',
    element: <LessonCreatePage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/:id',
    element: <LessonEditPage />,
  },
  {
    path: '/hub/courses/:course_id/chapters/:chapter_id/lessons/:id/edit',
    element: <LessonEditPage />,
  },
];

export default hubRoutes;
